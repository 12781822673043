/**
 * 营销活动 - 裂变活动
 */
import {
  getEmailRoutePath,
  getFacebookRoutePath,
  getTelegramRoutePath,
  getTwitterRoutePath,
  getWhatsAppWehRoutePath,
} from '@/helper/route/marketing'
import { t } from '@lingui/macro'

/** 分享手机号状态 */
export enum StatusTypeEnum {
  /** 正常 */
  normal = 'normal',
  /** 号码已用尽 */
  phoneEnd = 'phoneEnd',
  /** 过期 */
  oven = 'oven',
}

/** 任务轮次状态 */
export enum FissionStatusEnum {
  /** 进行中 */
  processing = 'processing',
  /** 已完成 */
  finished = 'finished',
  /** 未完成 */
  undone = 'undone',
}

/**
 * 活动状态
 */
export enum FissionActivityStatusEnum {
  /** 进行中 */
  processing = 'processing',
  /** 未开始 */
  notStart = 'not_started',
  /** 已结束 */
  ends = 'ends',
}

/** 奖项类型 */
export enum FissionPrizeTypeEnum {
  /** sweepstakes-抽奖 */
  sweepstakes = 'sweepstakes',
  /** invitation-邀请 */
  invitation = 'invitation',
  /** init-任务初始化 */
  init = 'init',
}

export function getAwardsTypeName(type: FissionPrizeTypeEnum | string) {
  return {
    [FissionPrizeTypeEnum.sweepstakes]: t`constants_marketing_fission_activity_qulbvl_34f`,
    [FissionPrizeTypeEnum.invitation]: t`constants_marketing_fission_activity_t40qtjh3rj`,
    [FissionPrizeTypeEnum.init]: t`constants_marketing_fission_activity_12cam0qztd`,
  }[type]
}

/** 是否已发送短信 */
export enum FissionSendMsgStatusEnum {
  /** Y-已发送 */
  yes = 'Y',
  /** N-未发送 */
  no = 'N',
}

/**
 * 分享程序名称
 */
export const enum AppNameEnum {
  /** mais */
  mais = 'Mais',
  /** facebook */
  facebook = 'Facebook',
  /** whatsApp */
  whatsApp = 'WhatsApp',
  /** twitter */
  twitter = 'Twitter',
  /** telegram */
  telegram = 'Telegram',
  /** email */
  email = 'Email',
}

/**
 * 生成程序的分享链接
 */
export function getShareLink(type: AppNameEnum | string, inviteLink) {
  const emailSubject = 'Check out this invite link'
  const emailBody = `Hi,Check out this invite link: ${inviteLink}`

  return {
    [AppNameEnum.facebook]: getFacebookRoutePath(encodeURIComponent(inviteLink)),
    [AppNameEnum.whatsApp]: getWhatsAppWehRoutePath(encodeURIComponent(`Check out this invite link: ${inviteLink}`)),
    [AppNameEnum.twitter]: getTwitterRoutePath(encodeURIComponent(inviteLink)),
    [AppNameEnum.telegram]: getTelegramRoutePath(encodeURIComponent(inviteLink)),
    [AppNameEnum.email]: getEmailRoutePath(encodeURIComponent(emailSubject), encodeURIComponent(emailBody)),
  }[type]
}
